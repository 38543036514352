import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/ContainerTwo';
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from 'common/src/components/Accordion';
import { Icon } from 'react-icons-kit';
import { thinDown } from 'react-icons-kit/entypo/thinDown';
import { thinRight } from 'react-icons-kit/entypo/thinRight';
import SectionWrapper, { ContentWrapper } from './features.style';
import data from 'common/src/data/AgencyModern';

const Features = () => {
  const Data = useStaticQuery(graphql`
    query {
      image1: file(
        relativePath: { eq: "image/agencyModern/features/feature.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      agencyModernJson {
        accordion {
          id
          title
          description
        }
      }
    }
  `);
  return (
    <SectionWrapper id="feature">
      <Container>
        <ContentWrapper>
          <div className="content">
            <Heading content="Sample ACT Question" />
            <Text content="This question was recently asked in the 2019 ACT exam." />
            <span className="banner-caption">
              <b>When x=3 and y=5, by how much does the value of 3x<sup>2</sup>-2y exceed the value of 2x<sup>2</sup>-3y?</b>
              <ul style={{ "padding-left": "25px", "text-align": "left" }}>
                <li>A. 4</li>
                <li>B. 14</li>
                <li>C. 16</li>
                <li>D. 20</li>
                <li>E. 50</li>
              </ul>
            </span>

            <Accordion>
              <Fragment>
                {data.accordion.map((item, index) => (
                  <AccordionItem key={index}>
                    <Fragment>
                      <AccordionTitle>
                        <Fragment>
                          <Heading as="h4" content={item.title} />
                          <IconWrapper>
                            <OpenIcon>
                              <Icon icon={thinRight} size={18} />
                            </OpenIcon>
                            <CloseIcon>
                              <Icon icon={thinDown} size={18} />
                            </CloseIcon>
                          </IconWrapper>
                        </Fragment>
                      </AccordionTitle>
                      <AccordionBody>
                        <br />
                        <b>Answer: B. 14</b><br/><br />
                        This problem can be solved by a simple substitution of the values x and y into the two algebraic equations.<br/><br />
                        <b>Step 1: Substitute Equation 1</b><br/>
                        3x<sup>2</sup>-2y and x=3, y=5 <br />
                        3(3)<sup>2</sup>-2(5) = 17<br /><br/>
                        <b>Step 2: Substitute Equation 2</b><br/>
                        2x<sup>2</sup>-3y and x=3, y=5 <br />
                        2(3)<sup>2</sup>-3(5) = 3<br /><br/>

                        <b>Step 3: Subtract Values</b><br/>
                        Equation 1 - Equation 2 = ?<br />
                        17 - 3 = 14<br />
                        Answer: B. 14<br />
                      </AccordionBody>
                    </Fragment>
                  </AccordionItem>
                ))}
              </Fragment>
            </Accordion>
          </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Features;
