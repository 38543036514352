import React, { useState } from 'react';
import { navigate } from 'gatsby';
import Container from 'common/src/components/UI/ContainerTwo';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Input from 'common/src/components/Input';
import Image from 'common/src/components/Image';
import { useAlert } from 'react-alert';
import axios from 'axios';
import displayErrors from '../../../utils';

import SectionWrapper, {
  FooterInner,
  Content,
  SubscriptionForm,
} from './subscribe.style';

import bg1 from 'common/src/assets/image/agencyModern/cta/1.png';
import bg2 from 'common/src/assets/image/agencyModern/cta/2.png';
import bg3 from 'common/src/assets/image/agencyModern/cta/3.png';
import bg4 from 'common/src/assets/image/agencyModern/cta/4.png';
import bg5 from 'common/src/assets/image/agencyModern/cta/5.png';

const Subscribe = () => {
  const alert = useAlert();
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const submitEmail = (email) => {
    setSubmitting(true);
    axios
      .post(`${process.env.GATSBY_API_URL}/subscribeEmail`, { email: email })
      .then((res) => {
        setSubmitting(false);
        setEmail('');
        alert.show(
          <div style={{ textTransform: 'initial' }}>{res.data.message}</div>,
          { type: 'success' }
        );
        navigate('/subscribe');
      })
      .catch((err) => {
        setSubmitting(false);
        displayErrors(err, alert);
      });
  };

  return (
    <SectionWrapper>
      <Container>
        <FooterInner>
          <Content>
            <Heading as="h3" content="Get FREE ACT practice problems" />
          </Content>
          <SubscriptionForm>
            <div>
              <Input
                inputType="email"
                placeholder="Enter Email Address"
                iconPosition="left"
                aria-label="email"
                value={email}
                onInput={(e) => setEmail(e.target.value)}
              />
              <Button
                title="Sign up (FREE)"
                type="submit"
                isLoading={submitting}
                onClick={() => submitEmail(email)}
              />
            </div>
          </SubscriptionForm>
        </FooterInner>
      </Container>
      <Image src={bg1} alt="bg1" className="illustration bg1" />
      <Image src={bg2} alt="bg2" className="illustration bg2" />
      <Image src={bg3} alt="bg3" className="illustration bg3" />
      <Image src={bg4} alt="bg4" className="illustration bg4" />
      <Image src={bg5} alt="bg5" className="illustration bg5" />
    </SectionWrapper>
  );
};

export default Subscribe;
