import React, { useState } from 'react';
import { navigate } from 'gatsby';
import Button from 'common/src/components/Button';
import Heading from 'common/src/components/Heading';
import Input from 'common/src/components/Input';
import Container from 'common/src/components/UI/ContainerTwo';
import { useAlert } from 'react-alert';
import Loader from 'common/src/components/Loader';
import { MetroSpinner } from 'react-spinners-kit';
import displayErrors from '../../../utils';

import BannerWrapper, { BannerContent, Subscribe } from './banner.style';
import axios from 'axios';

function Banner(props) {
  const alert = useAlert();
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const submitEmail = (email) => {
    setSubmitting(true);
    axios
      .post(`${process.env.GATSBY_API_URL}/subscribeEmail`, { email: email })
      .then((res) => {
        setSubmitting(false);
        setEmail('');
        alert.show(
          <div style={{ textTransform: 'initial' }}>{res.data.message}</div>,
          { type: 'success' }
        );
        navigate('/subscribe');
      })
      .catch((err) => {
        setSubmitting(false);
        displayErrors(err, alert);
      });
  };

  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Heading as="h1" content="Daily ACT practice. It's free." />
          <span className="banner-caption">
            Join <b>Perfect Score & Scholarship Winning</b> test tutor with
            daily ACT practice problems.
            <br />
            <b>Here's how it works:</b>
            <ul style={{ 'padding-left': '25px', 'text-align': 'left' }}>
              <li style={{ 'list-style-type': 'disc' }}>
                Sign up to receive a real act question in your mailbox daily.
              </li>
              <li style={{ 'list-style-type': 'disc' }}>
                Try to solve the problem! It's fun and sharpens your test-taking
                skills.
              </li>
              <li style={{ 'list-style-type': 'disc' }}>
                <b>(PRO)</b> Receive solutions with complete explanation and
                intuition.
              </li>
            </ul>
          </span>
          <Subscribe>
            <Input
              inputType="email"
              placeholder="Enter Email Address"
              iconPosition="left"
              aria-label="email"
              value={email}
              onInput={(e) => setEmail(e.target.value)}
            />
            <Button
              title="Sign up (FREE)"
              type="button"
              isLoading={submitting}
              onClick={() => submitEmail(email)}
            />
          </Subscribe>
        </BannerContent>
      </Container>
    </BannerWrapper>
  );
}

export default Banner;
